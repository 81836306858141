<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-card-text>
      <v-form class="multi-col-validation mt-6">
        <v-row>

          <!-- <v-col
            md="6"
            cols="12"
          >
          <label for="">Company</label>
            <v-chip
                class="ma-2"
                color="green"
                text-color="white"
              >
                {{account.company_name}}
              </v-chip>
          </v-col> -->
          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="user.email"
              label="Email"
              dense
              outlined
            ></v-text-field>
          </v-col>
          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="user.name"
              label="Name"
              dense
              outlined
            ></v-text-field>
          </v-col>
          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="profile.first_name"
              label="First Name"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="profile.last_name"
              label="Last Name"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="profile.address"
              label="Address"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="profile.phone"
              label="Phone"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="profile.city"
              label="City"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="profile.country"
              label="Country"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="profile.zip"
              label="Zip"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <v-select
                :items="roles"
                v-model="user.roles"
                multiple
                dense
                label="Select role"
                item-text="name"
                item-value="id"
                chips
            ></v-select>
          </v-col>

          <v-col cols="12">
            <v-btn
              color="primary"
              class="me-3 mt-4"
              @click.prevent="updateUser"
            >
              Save changes
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              class="mt-4"
              type="reset"
              @click.prevent="cancel"
            >
              Cancel
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from '@axios'
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      user: [],
      profile: [],
      roles: [],
    }
  },
  computed: {
    ...mapGetters({
      hasUserRole: 'auth/hasUserRole',
      hasUserPermission: 'auth/hasUserPermission',
    }),
  },
  methods: {
    async getUser() {
      this.user = JSON.parse(localStorage.getItem('userEdit'))
      this.profile = this.user.profile
    },
    async getRoles() {
      await axios
        .get('/role')
        .then(response => {
          this.roles = response.data
        })
        .catch(() => {
          // TODO: Next Update - Show notification
          console.error('Oops, Unable to get roles!')
          console.log('error :>> ', error.response)
        })
    },
    updateUser() {
      axios
        .post('/user', { profile: this.profile, user: this.user })
        .then(response => {
          console.log('updated')
          console.log(response.data)
          localStorage.removeItem('userEdit')
          this.updateRoles()
        })
        .catch(error => {
          // TODO: Next Update - Show notification
          console.error('Oops, Unable to update!')
          console.log('error :>> ', error.response)
          errorMessages.value = error.response.data.error
        })
    },
    updateRoles() {
      axios
        .post('/user/create-rol', { user: this.user.id, roles: this.user.roles })
        .then(response => {
          this.$alertify.success('User updated')
          console.log(response.data)
          localStorage.removeItem('userEdit')
          this.$router.push('/users')
        })
        .catch(error => {
          // TODO: Next Update - Show notification
          console.error('Oops, Unable to update!')
          console.log('error :>> ', error.response)
          errorMessages.value = error.response.data.error
        })
    },
    cancel() {
      this.$router.push('/users')
    },
  },
  async mounted() {
    if (this.hasUserRole('Super Admin') || this.hasUserRole('Client Owner')) {
      await this.getRoles()
      await this.getUser()
    } else {
      this.loading = false
      this.$router.push({ name: 'misc-not-authorized' })
    }
  },
}
</script>
